<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Por favor, envie os arquivos que compo&ecirc;m ao INDGER
    </div>
    <v-row
      id="importacao-encoding"
      class="mx-auto"
    >
      <v-col cols="12">
        <select-encoding
          id="fs-encoding-importacao-indger"
          label="Codificação dos arquivos"
          type="outlined"
          @encoding:selected="encodingSelected"
        />
      </v-col>
    </v-row>
    <v-row
      id="importacao-upload-files"
      class="mx-auto"
    >
      <v-col
        cols="12"
        class="container-upload"
      >
        <upload-files
          id="fs-upload-importacao-indger"
          :allowedFiles="allowedFiles"
          @file:uploaded="handleFileUploaded"
        />
      </v-col>
    </v-row>
    <v-row
      id="importacao-uploaded-files-table"
      class="mx-auto"
    >
      <v-col cols="12">
        <tabela-mapeamento-arquivos
          :arquivos="uploadedFiles"
          :extensoes-permitidas="allowedFiles"
          @mapeamento:atualizado="handleMapeamentoAtualizado"
          @mapeamento:apagar-arquivo="handleMapeamentoArquivoApagado"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import ImportacoesIndgerService from '@/services/ImportacoesIndgerService';

export default {
  name: 'ImportacoesIndgerSecondTabContent',
  components: {
    SelectEncoding: () => import('@/components/general/SelectEncoding.vue'),
    UploadFiles: () => import('@/components/general/UploadFiles/Index.vue'),
    TabelaMapeamentoArquivos: () => import('@/components/administracao/importacoes-indger/TabelaMapeamentoArquivos.vue')
  },
  data() {
    return {
      encoding: 'UTF-8',
      allowedFiles: [],
      uploadedFiles: []
    };
  },
  watch: {
    encoding() {
      this.emitEventImportUpdated();
    }
  },
  mounted() {
    this.getExtensoesPermitidas();
  },
  methods: {
    emitEventImportUpdated() {
      this.$emit('import:updated', {
        encoding: this.encoding
      });
    },
    encodingSelected(event) {
      this.encoding = event;
    },
    getExtensoesPermitidas() {
      ImportacoesIndgerService.getExtensoesPermitidas()
        .then((response) => (this.allowedFiles = response))
        .catch((error) => console.error(error));
    },
    handleFileUploaded(event) {
      const { files } = event;
      files.forEach((file) => this.uploadedFiles.push(file));
    },
    handleMapeamentoAtualizado(event) {
      this.$emit('mapeamento:atualizado', event);
    },
    handleMapeamentoArquivoApagado(event) {
      this.uploadedFiles = this.uploadedFiles.filter(
        (item) => item.id !== event.id
      );
      this.$emit('mapeamento:apagar-arquivo', event);
    }
  }
};
</script>

<style>
.container-upload {
  padding-top: 0;
}
</style>
